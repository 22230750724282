<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Employee Salary Prediction
      </h2>

      <img
        src="../../assets/img/predictive_banner.jpg"
        alt="Employee Salary Prediction Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-8">
          Machine-learned explanatory model for individual
          employee salary predictions based on Job,
          Incumbent & Performance Model and Labour Productivity.
        </p>
      <br/>
      <br/>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
